import React  from 'react'
import { withStore } from 'react-ion-store'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'

import Header from 'components/Header'

import styles from './styles'
import withHocs from 'utils/hocs/withHocs'
import Typography from '@material-ui/core/Typography'
import Switch from '@material-ui/core/Switch'
import Loading from 'components/Loading'
import getGeolocDetailLevel from './Services/getGeolocDetailLevel'
import updateGeolocDetailLevel from './Services/updateGeolocDetailLevel'


const GeolocView = (props) => {
    const [geolocDetailLevelLoading, setGeolocDetailLevelLoading] = React.useState(false)
    const [error, setError] = React.useState(null)
    const [geolocDetailLevel, setGeolocDetailLevel] = React.useState(null)


    React.useEffect(() => {
        loadData()
        document.title = props.translate('module_geoloc')
    }, [])


    const loadData = async () => {
        setGeolocDetailLevelLoading(true)
        setError(null)
        const response = await getGeolocDetailLevel()
        if(response.status === 200){
            setGeolocDetailLevel(response.data.detailLevel)
        }
        else{
            setError("Error retrieving data!")
        }
        setGeolocDetailLevelLoading(false)
    }

    const onSwitchChange = async (pos) => {
        if((props.roles && props.roles.geoloc && props.roles.geoloc.write === true) ||
            (Array.isArray(props.roles.geoloc.write) && props.roles.geoloc?.write.length > 0)){
                setGeolocDetailLevelLoading(true)
                const response = await updateGeolocDetailLevel(pos)
                if(response.status === 200){
                    loadData()
                }
                else{
                    setError("Error updating data!")
                    setGeolocDetailLevelLoading(false)
                }
            } 
    }

    return (
        <React.Fragment>
        <Header isMenu title={props.translate('module_geoloc')} />
        {geolocDetailLevelLoading ? <Loading></Loading> : <>
            {error != null ? 
        <p style={{marginLeft: '8px'}}>{error}</p> 
        :
        <div className={props.classes.submenu}>
                <Typography style={{marginBottom: '16px'}} variant="h6">Detail level</Typography>
                <div style={{marginLeft: '16px', height: 'auto'}}>
                        <div>
                            {props.translate('manage_switch_label_off')}
                            <Switch color="primary" 
                                checked={geolocDetailLevel > 0}
                                onChange={() => onSwitchChange(geolocDetailLevel > 0 ? 0 : 1)}
                            />  
                            <p style={{display: 'inline-block'}}>{props.translate('manage_switch_label_on')}</p>
                            <p className={props.classes.detailLevelName}>
                                {props.translate('geoloc_detail_level0')}</p>
                        </div>   
                        <div>
                            {props.translate('manage_switch_label_off')}
                            <Switch color="primary" 
                                checked={geolocDetailLevel > 1}
                                onChange={() => onSwitchChange(geolocDetailLevel > 1 ? 1 : 2)}
                            />  
                            <p style={{display: 'inline-block'}}>{props.translate('manage_switch_label_on')}</p>
                            <p className={props.classes.detailLevelName}>
                                {props.translate('geoloc_detail_level1')}</p>
                        </div>   
                        <div>
                            {props.translate('manage_switch_label_off')}
                            <Switch color="primary" 
                                checked={geolocDetailLevel > 2}
                                onChange={() => onSwitchChange(geolocDetailLevel > 2 ? 2 : 3)}
                            /> 
                            <p style={{display: 'inline-block'}}>{props.translate('manage_switch_label_on')}</p>
                            <p className={props.classes.detailLevelName}>
                                {props.translate('geoloc_detail_level2')}</p>
                            
                        </div>
                        <div>
                            {props.translate('manage_switch_label_off')}
                            <Switch color="primary" 
                                checked={geolocDetailLevel > 3}
                                onChange={() => onSwitchChange(geolocDetailLevel > 3 ? 3 : 4)}
                            /> 
                            <p style={{display: 'inline-block'}}>{props.translate('manage_switch_label_on')}</p>
                            <p className={props.classes.detailLevelName}>
                                {props.translate('geoloc_detail_level3')}</p>
                        </div>
                </div>
            </div>
        }
        </>
        }
        </React.Fragment>
    )
}

GeolocView.propTypes = {
    translate: PropTypes.func,
    store: PropTypes.object,
    history: PropTypes.object,
    __ion_status: PropTypes.object,
    roles: PropTypes.object,
    classes: PropTypes.object,
}

export default withHocs(withStore, withStyles(styles))(GeolocView)
