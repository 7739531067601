/* eslint-disable max-len */
import React from 'react'
import { object, func, bool, string } from 'prop-types'
import { Translate } from 'react-localize-redux'

import Paper from '@material-ui/core/Paper'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

import PrimaryButton from 'components/PrimaryButton'
import LanguagePicker from 'components/LanguagePicker'
import PortalLogo from 'components/PortalLogo'

const LoginView = ({ classes, handleLogin, loginProps, showNewUrlMessage, redirect_uri }) => (
    <React.Fragment>
        <AppBar color="default" elevation={1}>
            <Toolbar className={classes.flex}>
                <PortalLogo className={classes.logo} />
                <Typography />
                <Typography variant="h6" className={classes.title}>
                    Login
                </Typography>
                <Typography />
            </Toolbar>
        </AppBar>
        <div className={classes.loginBody}>
            <Paper className={classes.loginDialog}>
                <Typography variant="h6" component="h2" className={classes.loginTitle}>
                    <Translate id="login_welcome" />
                </Typography>
                <Typography className={classes.loginText}>
                    <Translate id="login_button_info" />
                </Typography>
                <PrimaryButton
                    title="LOGIN"
                    className={classes.loginButton}
                    onClick={handleLogin}
                    disabled={!loginProps.loginPossible}
                />
                {loginProps.error && (
                    <Typography className={classes.errorText}>
                        <Translate id="page_error_label" />
                    </Typography>
                )}
            </Paper>
            <div className={classes.languagePicker}>
                <LanguagePicker alwaysWhite />
            </div>
            {showNewUrlMessage == true ?
                <div className={classes.urlMessage}>
                <Translate>
                    {({ translate }) => (
                        <>
                            <p className={classes.urlMessageText}>
                                {translate('new_url_message_part_1')} <a className={classes.urlMessageLink} href={redirect_uri.substring(0, redirect_uri.length - 5)}>{redirect_uri.substring(0, redirect_uri.length - 5)}</a>{'.'} {translate('new_url_message_part_2')}
                            </p>
                        </>
                    )}
                </Translate>
            </div>
            :
            null
            }
        </div>
    </React.Fragment>
)

LoginView.propTypes = {
    classes: object.isRequired,
    handleLogin: func.isRequired,
    loginProps: object.isRequired,
    showNewUrlMessage: bool.isRequired,
    redirect_uri: string.isRequired,
}

export default LoginView
