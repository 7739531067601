/* eslint-disable react/prop-types */

// Logic
import React from 'react'
import { compose } from 'recompose'
import { withStore, withCall } from 'react-ion-store'
import queryParamEncoder from 'utils/queryParamEncoder'

// Presentation

// Components

// Assets

const callKey = 'documentSearchResults'

const buildParamsObjectForCall = callData => ({
    ...(callData.idfNumber && { idfNumber: callData.idfNumber }),
    ...(callData.SearchBy && { SearchBy: callData.SearchBy }),
    ...(callData.Sort && { Sort: callData.Sort }),
    ...(callData.SortDirection && { SortDirection: callData.SortDirection }),
    ...(callData.Filter && { Filter: callData.Filter }),
    ...(callData.FolderId && { FolderId: callData.FolderId }),
    ...(callData.OrganisationId && { OrganisationId: callData.OrganisationId }),
    ...(callData.SearchDocument && { SearchDocument: callData.SearchDocument }),
})

export default compose(
    withStore,
    /* eslint-disable */
    Component => ({ store, value }) => {
        /* eslint-enable */
        // eslint-disable-next-line react/prop-types
        const callData = store.get('callData')

        if (callData) {
            const date = callData.date

            // if (callData.SearchBy === 'document' && value === 0) {
            let params = buildParamsObjectForCall(callData)
            return withCall(callKey, {
                config: {
                    method: 'get',
                    url: `/document?date=${date}`,
                    params: params,
                    paramsSerializer: queryParamEncoder,
                },
                reducer: response => {
                    if (response.status === 200) return response.data
                    return []
                },
            })(Component)({ store })
            // }

            // if (callData.SearchBy === 'trainDriver' && value === 1) {
            ///profile?date=21082020&organisationid=15&search=999804379

            // return withCall(callKey, {
            //     config: {
            //         method: 'get',
            //         url: `/profile?date=${date}&search=${callData.idfNumber}`,
            //         params: {
            //             idfNumber: callData.idfNumber,
            //             SearchBy: callData.SearchBy,
            //             Sort: callData.Sort,
            //             SortDirection: callData.SortDirection,
            //             Filter: callData.Filter,
            //             FolderId: callData.FolderId,
            //             OrganisationId: callData.OrganisationId,
            //             SearchDocument: callData.SearchDocument,
            //         },
            //         paramsSerializer: queryParamEncoder,
            //     },
            //     reducer: response => {
            //         if (response.status === 200) return response.data
            //         return []
            //     },
            // })(Component)({ store })
            // }
        }

        return <Component />
    }
)(() => <div />)
