/* eslint-disable max-len */
import React from 'react'
import { compose } from 'recompose'
import { withStore } from 'react-ion-store'

import { func, object, string, number, array } from 'prop-types'
import classNames from 'classnames'
import { withLocalize, Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import { Edit } from '@material-ui/icons'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import mapValues from 'lodash/mapValues'
import { DateTime } from 'luxon'

import Header from 'components/Header'
import Loading from 'components/Loading'

import PtdesDrawerView from './components/PtdesDrawerView'
import LindaDrawerView from './components/LindaDrawerView'
import getPtdesData from './getPtdesData'
import getPtdesKmMarkdata from './getPtdesKmMarkdata'
import PtdesTable from './PtdesTable'
import updatePtDesLindaInteractionSettings from './updatePtDesLindaInteractionSettings'

import styles from './styles'

import PtDesType from './Enums/PtDesType'

const seprator = '-'
const columns = [
    {
        label: 'datatable_header_actions',
        dataKey: 'checkbox',
        widthPercentage: 0.03
    },
    {
        label: 'datatable_header_ptcar_line',
        dataKey: 'userLineName',
        widthPercentage: 0.065
    },
    {
        label: 'datatable_header_ptcarplatform_ptcar',
        dataKey: 'ptCarCommercialShortName',
        widthPercentage: 0.165
    },
    {
        label: 'datatable_header_ptdesplatform_ptdes',
        dataKey: 'ptDesName',
        widthPercentage: 0.165
    },
    {
        label: 'datatable_header_ptdesplatform_id',
        dataKey: 'ptDesId',
        widthPercentage: 0.1
    },
    {
        label: 'label_type',
        dataKey: 'ptDesType',
        widthPercentage: 0.1
    },
    {
        label: 'datatable_header_ptdesplatform_milestone',
        dataKey: 'milestone',
        widthPercentage: 0.165
    },
    {
        label: 'datatable_header_ptcar_latitude',
        dataKey: 'latitude',
        widthPercentage: 0.065
    },
    {
        label: 'datatable_header_ptcar_longitude',
        dataKey: 'longitude',
        widthPercentage: 0.065
    },
    {
        label: 'datatable_header_actions',
        dataKey: 'actions',
        widthPercentage: 0.065
    },
]
const rowHeight = 54

class PtdesListView extends React.Component {
    state = {
        data: [],
        dataPtDes: [],
        isOpen: false,
        filteredData: [],
        rowData: {},
        lineFilterValue: '',
        ptCarFilterValue: '',
        ptDesFilterValue: '',
        loadingData: false,
        checkedTasks: [],
        checkAll: false,
        openLindaDrawer: false,
        lindaSettingDates: {
            registerToLinda: null,
            vgProcedureStarted: null,
            vgExecuted: null,
            departureOnHold: null,
            personWithLimitedPossibility: null,
            extraLindaStop: null,
            notifyAll: null,
            receiveAll: null,
            displayIconuitgesteldvertrek: null,
        },
        lindaSettingUpdateError: '',
    }

    static propTypes = {
        classes: object.isRequired,
        rowClassName: string,
        onRowClick: func,
        headerHeight: number,
        activeLanguage: object,
        translate: func,
        lineFilterValue: string,
        ptCarFilterValue: string,
        ptDesFilterValue: string,
        data: array,
        dataPtDes: array,
        filteredData: array,
    }

    mapDataForLines = (PtdesData, PtdesKmMarkData) => {
        let newData = []
        PtdesData.map(item => {
            const ptdesKmMark = PtdesKmMarkData.filter((obj) => obj.ptDesId == item.ptDesId)
            if (ptdesKmMark.length === 0) {
                newData.push({ lineIndex: null, ptdesKmMark, ...item })
            }
            if (ptdesKmMark.length > 0) {
                ptdesKmMark.forEach((lineNumber, index) => {
                    return newData.push({ lineIndex: index, ptdesKmMark, ...item })
            })
            }
        })
        const data = newData.filter((obj) => obj.lineIndex != null)
        return this.userLineNameSort(data)
    }


    userLineNameSort = (data) => {
        //Initially sort purely on initial numbers
        let sorted = data.sort((a, b) => parseInt(a.ptdesKmMark[a.lineIndex].userLineName) - parseInt(b.ptdesKmMark[b.lineIndex].userLineName))
        let subSorted = []
        //Group all same numbers in subArray
        sorted.forEach((el, ind) => {
            //first one can always be inserted as a new array
            if (ind === 0) {
                subSorted[0] = [el]
                return null
            }

    
            //If userlinename is not the same as currentsubgroup then find a subgroup that includes it else create new group
            let groupfound = false
            subSorted.forEach((subEl, subSortInd) => {
                if (parseInt(subEl[0].ptdesKmMark[subEl[0].lineIndex].userLineName) === parseInt(el.ptdesKmMark[el.lineIndex].userLineName)) {
                    subSorted[subSortInd].push(el)
                    groupfound = true
                }
            })
            if (!groupfound) {
                subSorted.push([el])
            }
            return null
        })

    
        // //Move () to front
    
        subSorted.forEach((el) => {

            el.sort((A, B) => {
                if (B.ptdesKmMark[B.lineIndex].userLineName.search(/[a-zA-Z]/) >= 0 || B.ptdesKmMark[B.lineIndex].userLineName.includes('/') 
                || B.ptdesKmMark[B.lineIndex].userLineName.includes('(')) return -1
                else return 0
            })

            el.sort((A, B) => {
                if (A.ptdesKmMark[A.lineIndex].userLineName.includes('(') && B.ptdesKmMark[B.lineIndex].userLineName.includes('/')) return -1
                else return 0
            })

            
            el.sort((A, B) => {
                if (A.ptdesKmMark[A.lineIndex].userLineName.includes('/') && B.ptdesKmMark[B.lineIndex].userLineName.search(/[a-zA-Z]/) >= 0 ) return -1
                else return 0
            })
            
            // sort letters alphabetically
            el.sort((A, B) => {
                let Aletter = A.ptdesKmMark[A.lineIndex].userLineName.search(/[a-zA-Z]/)
                let Bletter = B.ptdesKmMark[B.lineIndex].userLineName.search(/[a-zA-Z]/)

                if (Aletter >= 0 && Bletter >= 0) {
                    if(A.ptdesKmMark[A.lineIndex].userLineName[Aletter] < B.ptdesKmMark[B.lineIndex].userLineName[Bletter]) return -1
                    if(A.ptdesKmMark[A.lineIndex].userLineName[Aletter] > B.ptdesKmMark[B.lineIndex].userLineName[Bletter]) return 1
                }
                else return 0
            })

            el.sort((A, B) => {
                let Aletter = A.ptdesKmMark[A.lineIndex].userLineName.search(/[a-zA-Z]/)
                let Bletter = B.ptdesKmMark[B.lineIndex].userLineName.search(/[a-zA-Z]/)
                if ((Aletter >= 0 && Bletter >= 0) && (A.ptdesKmMark[A.lineIndex].userLineName[Aletter] == B.ptdesKmMark[B.lineIndex].userLineName[Aletter])) {
                        if(B.ptdesKmMark[B.lineIndex].userLineName[Aletter + 1] == '(' && A.ptdesKmMark[A.lineIndex].userLineName[Aletter + 1] == undefined) return -1
                        if(!A.ptdesKmMark[A.lineIndex].userLineName.includes('/') && B.ptdesKmMark[B.lineIndex].userLineName.includes('/')) return -1
                }
                else return 0
            })

            el.sort((A, B) => {
                if (A.ptdesKmMark[A.lineIndex].userLineName.includes('/') && B.ptdesKmMark[B.lineIndex].userLineName.includes('/')) {
                    let Aletter = A.ptdesKmMark[A.lineIndex].userLineName.search(/[a-zA-Z]/)
                    let Bletter = B.ptdesKmMark[B.lineIndex].userLineName.search(/[a-zA-Z]/)

                    if (Aletter >= 0 && Bletter >= 0){
                        if (A.ptdesKmMark[A.lineIndex].userLineName[Aletter] == B.ptdesKmMark[B.lineIndex].userLineName[Aletter]) {
                            return A.ptdesKmMark[A.lineIndex].userLineName.slice(A.ptdesKmMark[A.lineIndex].userLineName.indexOf('/') + 1) - B.ptdesKmMark[B.lineIndex].userLineName.slice(B.ptdesKmMark[B.lineIndex].userLineName.indexOf('/') + 1)
                        }
                    }
                
                    else{
                        return A.ptdesKmMark[A.lineIndex].userLineName.slice(A.ptdesKmMark[A.lineIndex].userLineName.indexOf('/') + 1) - B.ptdesKmMark[B.lineIndex].userLineName.slice(B.ptdesKmMark[B.lineIndex].userLineName.indexOf('/') + 1)
                    }
                }

                else return 0
            })

            // sort based on distanceToZero
            el.sort((A, B) => {
                if (A.ptdesKmMark[A.lineIndex].userLineName == B.ptdesKmMark[B.lineIndex].userLineName) {
                    return A.ptdesKmMark[A.lineIndex].distanceToZero - B.ptdesKmMark[B.lineIndex].distanceToZero
                }
                else return 0
            })

            // sort based on PtCar name (alphabetical order)
            el.sort((A, B) => {
                if (Math.abs(A.ptdesKmMark[A.lineIndex].distanceToZero) == Math.abs(B.ptdesKmMark[B.lineIndex].distanceToZero) && A.ptdesKmMark[A.lineIndex].userLineName == B.ptdesKmMark[B.lineIndex].userLineName) {
                    if (this.getCommercialName(this.props.activeLanguage, A.ptCarCommercialShortName) < this.getCommercialName(this.props.activeLanguage, B.ptCarCommercialShortName)) return -1
                    if (this.getCommercialName(this.props.activeLanguage, A.ptCarCommercialShortName) > this.getCommercialName(this.props.activeLanguage, B.ptCarCommercialShortName)) return 1
                }
                else return 0
            })

            // sort based on ptdesType
            el.sort((A, B) => { 
                if (Math.abs(A.ptdesKmMark[A.lineIndex].distanceToZero) == Math.abs(B.ptdesKmMark[B.lineIndex].distanceToZero) && A.ptdesKmMark[A.lineIndex].userLineName == B.ptdesKmMark[B.lineIndex].userLineName && this.getCommercialName(this.props.activeLanguage, A.ptCarCommercialShortName) == this.getCommercialName(this.props.activeLanguage, B.ptCarCommercialShortName)) {
                    return PtDesType[A.ptDesType] - PtDesType[B.ptDesType]
                }
                else return 0
            })

            // sort based on PtDes name (alphabetical order)
            el.sort((A, B) => { 
                if (Math.abs(A.ptdesKmMark[A.lineIndex].distanceToZero) == Math.abs(B.ptdesKmMark[B.lineIndex].distanceToZero) && A.ptdesKmMark[A.lineIndex].userLineName == B.ptdesKmMark[B.lineIndex].userLineName && this.getCommercialName(this.props.activeLanguage, A.ptCarCommercialShortName) == this.getCommercialName(this.props.activeLanguage, B.ptCarCommercialShortName) && A.ptDesType == B.ptDesType) {
                    if ((this.props.activeLanguage.code === 'fr' ? A.ptdesKmMark[A.lineIndex].longNameFr < B.ptdesKmMark[B.lineIndex].longNameFr : A.ptdesKmMark[A.lineIndex].longNameNl < B.ptdesKmMark[B.lineIndex].longNameNl)) return -1
                    if ((this.props.activeLanguage.code === 'fr' ? A.ptdesKmMark[A.lineIndex].longNameFr > B.ptdesKmMark[B.lineIndex].longNameFr : A.ptdesKmMark[A.lineIndex].longNameNl > B.ptdesKmMark[B.lineIndex].longNameNl)) return 1
                }
                else return 0
            })

        })

        sorted = subSorted.flat()
    
        return sorted
    }

    onCloseLindaDrawer = () => {
        var lindaDates = { ...this.state.lindaSettingDates }
        // set all the dates to null on close drawer
        lindaDates = mapValues(lindaDates, () => null)
        this.setState({
            openLindaDrawer: false,
            lindaSettingDates: lindaDates,
            checkedTasks: [],
            checkAll: false,
            lindaSettingUpdateError: '',
        })
    }

    handleCopyToAll = type => {
        let lindaDates = { ...this.state.lindaSettingDates }
        let toCopy = lindaDates[type]
        let newDates = mapValues(lindaDates, () => toCopy)
        this.setState({ lindaSettingDates: newDates })
    }
    dateChangeHandler = (type, value) => {
        let lindaDates = { ...this.state.lindaSettingDates }
        if (value) lindaDates[type] = value / 1000
        // converting millis to seconds
        else lindaDates[type] = null
        this.setState({ lindaSettingDates: lindaDates })
    }

    changeDate = date => {
        if (date) return DateTime.fromSeconds(parseInt(date))
        else return null
    }

    onLindaSettingSave = () => {
        const { lindaSettingDates } = this.state
        let checkedPtDesList = [...this.state.checkedTasks]
        let dataToSend = []
        for (let checkedPtDes of checkedPtDesList) {
            let checkObj = checkedPtDes.split(seprator)
            let lindaSetting = {}

            lindaSetting.LineId = checkObj[1]
            lindaSetting.PtDesId = checkObj[0]
            lindaSetting.DepartureOnHoldActivationDate = this.changeDate(lindaSettingDates.departureOnHold)
            lindaSetting.DepartureOnHoldActive = !!lindaSettingDates.departureOnHold
            lindaSetting.PersonWithLimitedPossibilityActivationDate = this.changeDate(lindaSettingDates.personWithLimitedPossibility)

            lindaSetting.PersonWithLimitedPossibilityActive = !!lindaSettingDates.personWithLimitedPossibility
            lindaSetting.ExtraLindaStopActivationDate = this.changeDate(lindaSettingDates.extraLindaStop)
            lindaSetting.ExtraLindaStopActive = !!lindaSettingDates.extraLindaStop
            lindaSetting.VgProcedureStartedActivationDate = this.changeDate(lindaSettingDates.vgProcedureStarted)
            lindaSetting.VgProcedureStartedActive = !!lindaSettingDates.vgProcedureStarted
            lindaSetting.VgExecutedActivationDate = this.changeDate(lindaSettingDates.vgExecuted)
            lindaSetting.VgExecutedActive = !!lindaSettingDates.vgExecuted
            lindaSetting.NotifyAllActivationDate = this.changeDate(lindaSettingDates.notifyAll)
            lindaSetting.NotifyAllActive = !!lindaSettingDates.notifyAll
            lindaSetting.ReceiveAllActivationDate = this.changeDate(lindaSettingDates.receiveAll)
            lindaSetting.ReceiveAllActive = !!lindaSettingDates.receiveAll
            lindaSetting.RegisterToLindaActivationDate = this.changeDate(lindaSettingDates.registerToLinda)
            lindaSetting.RegisterToLindaActive = !!lindaSettingDates.registerToLinda
            lindaSetting.SuspendDepartureActivationDate = this.changeDate(lindaSettingDates.displayIconuitgesteldvertrek)

            lindaSetting.SuspendDepartureActive = !!lindaSettingDates.displayIconuitgesteldvertrek
            dataToSend.push(lindaSetting)
        }
        this.lindaSettingUpdate(dataToSend)
    }

    lindaSettingUpdate = async (data) => {
        const response = await updatePtDesLindaInteractionSettings(data)

        if(response.status == 200){
            this.onCloseLindaDrawer()
            this.loadPtdes()
            return
        }

        if (response.status === 400) {
            this.setState({
                lindaSettingUpdateError: response.data.Message,
            })
        } else {
            this.setState({
                lindaSettingUpdateError: 'Error occured',
            })
        }
    }

    lindaSettingClick = () => {
        if (this.state.checkedTasks.length === 1) {
            let ptDesList = this.getData()
            let checkedPtDes = this.state.checkedTasks[0]
            let selectedPtDes
            for (let i = 0; i < ptDesList.length; i++) {
                let ptDesObj = ptDesList[i]
                let uniqueKey = `${ptDesObj.ptDesId}${seprator}${ptDesObj.ptdesKmMark[ptDesObj.lineIndex].lineId}`
                if (checkedPtDes === uniqueKey) {
                    selectedPtDes = ptDesObj.ptdesKmMark[ptDesObj.lineIndex]?.lindaInteractionSettings
                }
            }
            let lindaSettingObj = { ...this.state.lindaSettingDates }
            if (selectedPtDes) {
                lindaSettingObj.registerToLinda = selectedPtDes.registerToLindaActivationDate
                lindaSettingObj.vgProcedureStarted = selectedPtDes.vgProcedureStartedActivationDate
                lindaSettingObj.vgExecuted = selectedPtDes.vgExecutedActivationDate
                lindaSettingObj.departureOnHold = selectedPtDes.departureOnHoldActivationDate
                lindaSettingObj.personWithLimitedPossibility = selectedPtDes.personWithLimitedPossibilityActivationDate
                lindaSettingObj.extraLindaStop = selectedPtDes.extraLindaStopActivationDate
                lindaSettingObj.notifyAll = selectedPtDes.notifyAllActivationDate
                lindaSettingObj.receiveAll = selectedPtDes.receiveAllActivationDate
                lindaSettingObj.displayIconuitgesteldvertrek = selectedPtDes.suspendDepartureActivationDate
                this.setState({ openLindaDrawer: true, lindaSettingDates: lindaSettingObj })
                return
            }
        }
        if (this.state.checkedTasks.length > 0) this.setState({ openLindaDrawer: true })
    }


    filterCheckedTasks = (filteredData) => {
        let checkedArray = []

        filteredData.forEach(item => {
            let uniqueKey = `${item.ptDesId}${seprator}${item.ptdesKmMark[item.lineIndex].lineId}`
            if(this.state.checkedTasks.includes(uniqueKey)){
                checkedArray.push(uniqueKey)
            }
        })
        this.setState({ checkedTasks: checkedArray})
    }

    handleCheckTasks = (e, id) => {
        e.stopPropagation()
        const { checkedTasks } = this.state
        if (checkedTasks.indexOf(id) > -1) {
            const removeFromList = checkedTasks.filter(item => item !== id)
            this.setState({ checkedTasks: removeFromList })
            return
        }

        const list = [...checkedTasks, id]
        this.setState({ checkedTasks: list })
    }

    handleCheckAll = () => {
        let listData = this.getData()
        let checkedArray = []

        if (this.state.checkAll) {
            this.setState({ checkedTasks: [], checkAll: false})
        } else {
            listData.forEach(item => {
                let uniqueKey = `${item.ptDesId}${seprator}${item.ptdesKmMark[item.lineIndex].lineId}`
                checkedArray.push(uniqueKey)
            })

            this.setState({ checkedTasks: checkedArray, checkAll: true})
        }
    }

    loadPtdes = async () => {
        let mappedData = []

        this.setState({ loadingData: true })

        let PtdesData = await getPtdesData()
        let PtdesKmMarkData = await getPtdesKmMarkdata()

        if (PtdesData && PtdesData.length) {
            mappedData = this.mapDataForLines(PtdesData, PtdesKmMarkData)
        }
        
        this.setState({
            data: mappedData,
            PtdesKmMarkData: PtdesKmMarkData,
            loadingData: false,
        })
        
        this.filterData()
    }

    componentDidMount() {
        document.title = this.props.translate('ptdes_overview')
        this.loadPtdes()
    }

    getRowClassName = ({ index }) => {
        const { classes, rowClassName, onRowClick } = this.props

        return classNames(classes.tableRow, classes.flexContainer, rowClassName, {
            [classes.tableRowHover]: index !== -1 && onRowClick != null,
        })
    }

    onClose = () => {
        this.setState({
            isOpen: false,
            rowData: {},
        })
    }

    handleEditClick = (e, rowData) => {
        this.onRowEditClick(e, rowData)
    }

    onRowEditClick = (e, rowData) => {
        this.setState({
            isOpen: true,
            rowData: rowData,
        })

        e.stopPropagation()
    }

    headerRenderer = headerProps => {
        const { classes, headerHeight } = this.props
        return (
            <TableCell
                component="div"
                className={classNames(classes.header, classes.flexContainer)}
                variant="head"
                style={{
                    height: headerHeight,
                    paddingTop: 34,
                    paddingBottom: 34,
                    paddingRight: 0,
                }}
            >
                {headerProps.dataKey !== 'checkbox' && (
                    <Typography>
                        <Translate id={headerProps.label} />
                    </Typography>
                )}
                {headerProps.dataKey === 'checkbox' && (
                    <Checkbox       
                        checked={this.state.checkAll}
                        onChange={() => this.handleCheckAll()}            
                        value={'all'}
                        color="primary"
                    />
                )}
            </TableCell>
        )
    }
    cellRenderer = cellProps => {
        const { activeLanguage, classes } = this.props
        const { dataKey, rowData } = cellProps
        let checkedTasks = this.state.checkedTasks

        if (dataKey === 'userLineName') {
            if (rowData['ptdesKmMark'].length > 0){
                return (
                    <TableCell
                    className={classes.tableCell}
                    component="div"
                    variant="body"
                    style={{ height: rowHeight}}
                    >
                        <Typography>{rowData['ptdesKmMark'][rowData.lineIndex].userLineName}</Typography>
                    </TableCell>
                )
            }
            return (
                <TableCell
                className={classes.tableCell}
                component="div"
                variant="body"
                style={{ height: rowHeight }}
                >
                    <Typography>{'N/A'}</Typography>
                </TableCell>
            )

        }

        if (dataKey === 'ptCarCommercialShortName') {
            return (
                <TableCell
                    className={classes.tableCell}
                    component="div"
                    variant="body"
                    style={{ height: rowHeight }}
                >
                    <Typography>{this.getCommercialName(activeLanguage, rowData[dataKey])}</Typography>
                </TableCell>
            )
        }

        if (dataKey === 'ptDesName') {
            if(rowData.lineIndex != null){
                return (
                    <TableCell
                        className={classes.tableCell}
                        component="div"
                        variant="body"
                        style={{ height: rowHeight}}
                    >
                        {/* eslint-disable-next-line max-len */}
                        <Typography>{this.getCommercialName(activeLanguage, [{language: 'fr-BE', translation : rowData['ptdesKmMark'][rowData.lineIndex].longNameFr}, {language: 'nl-BE', translation : rowData['ptdesKmMark'][rowData.lineIndex].longNameNl}])}</Typography>
                    </TableCell>
                )
            }
            return (
                <TableCell
                    className={classes.tableCell}
                    component="div"
                    variant="body"
                    style={{ height: rowHeight }}
                >
                    <Typography>{this.getCommercialName(activeLanguage, rowData['shortName'])}</Typography>
                </TableCell>
            )
        }

        if (dataKey === 'milestone') {
            if (rowData['ptdesKmMark'].length > 0){
                return (
                    <TableCell
                        className={classes.tableCell}
                        component="div"
                        variant="body"
                        style={{ height: rowHeight }}
                    >
                        <Typography>{rowData['ptdesKmMark'][rowData.lineIndex].milestone}</Typography>
                    </TableCell>
                )
            }
                
            return (
                <TableCell
                className={classes.tableCell}
                component="div"
                variant="body"
                style={{ height: rowHeight }}
                >
                    <Typography>{'-'}</Typography>
                </TableCell>
            )
        }

        if (dataKey === 'latitude') {
            return (
                <TableCell
                    className={classes.tableCell}
                    component="div"
                    variant="body"
                    style={{ height: rowHeight }}
                >
                    <Typography>{rowData.coordinate.latitude? rowData.coordinate.latitude: '-'}</Typography>
                </TableCell>
            )
        }
        if (dataKey === 'longitude') {
            return (
                <TableCell
                    className={classes.tableCell}
                    component="div"
                    variant="body"
                    style={{ height: rowHeight }}
                >
                    <Typography>{rowData.coordinate.longitude? rowData.coordinate.longitude: '-'}</Typography>
                </TableCell>
            )
        }

        if (dataKey === 'code') {
            return (
                <TableCell
                    className={classes.tableCell}
                    component="div"
                    variant="body"
                    style={{ height: rowHeight }}
                >
                    <Typography>{rowData[dataKey]}</Typography>
                </TableCell>
            )
        }

        if (dataKey === 'uic') {
            return (
                <TableCell
                    className={classes.tableCell}
                    component="div"
                    variant="body"
                    style={{ height: rowHeight }}
                >
                    <Typography>{rowData[dataKey]}</Typography>
                </TableCell>
            )
        }

        if (dataKey === 'ptDesId') {
            return (
                <TableCell
                    className={classes.tableCell}
                    component="div"
                    variant="body"
                    style={{ height: rowHeight }}
                >
                    <Typography>{rowData[dataKey]}</Typography>
                </TableCell>
            )
        }

        if (dataKey === 'ptDesType') {
            return (
                <TableCell
                    className={classes.tableCell}
                    component="div"
                    variant="body"
                    style={{ height: rowHeight }} 
                >
                    <Typography>{rowData[dataKey]}</Typography>
                </TableCell>
            )
        }

        if (dataKey === 'actions') {
            return (
                <TableCell
                    className={classes.tableActionCell}
                    component="div"
                    variant="body"
                    style={{ height: rowHeight }}
                >
                    <div className={classes.multipleIcons}>
                        <Edit
                            className={classes.editIcon}
                            onClick={e => {
                                this.onRowEditClick(e, rowData)
                            }}
                        />
                    </div>
                </TableCell>
            )
        }
        if (dataKey === 'checkbox') {
            let uniqueKey = `${rowData.ptDesId}${seprator}${rowData.ptdesKmMark[rowData.lineIndex].lineId}`
            return (
                <TableCell
                    className={classes.tableCell}
                    component="div"
                    variant="body"
                    style={{ height: rowHeight}}
                >
                    <Checkbox
                        checked={checkedTasks.indexOf(uniqueKey) > -1}
                        onChange={e => this.handleCheckTasks(e, uniqueKey)}
                        value={uniqueKey}
                        color="primary"
                    />
                </TableCell>
            )
        }
    }

    handleSearchFilters = (filteredString, stateProperty) => {

        this.setState(
            {
                [stateProperty]: filteredString,
            },
            () => {
                this.filterData()
            }
        )
    }


    filterData = () => {
        let data = this.state.data
        const ptDesFilterValue = this.state.ptDesFilterValue
            
        let filteredData = ptDesFilterValue != '' ? data.filter(item => {
                return item.lineIndex !== null
                    ? ((item.ptDesLookup.ptDes.find((el) => el.toLowerCase() == ptDesFilterValue.toLowerCase()) || item.ptDesLookup.ptCar.find((el) => el.toLowerCase() == ptDesFilterValue.toLowerCase()) || item.ptDesLookup.other.find((el) => el.toLowerCase() == ptDesFilterValue.toLowerCase())
                        || item.ptDesId.toString().substring(0, ptDesFilterValue.length).includes(ptDesFilterValue)))
                    : null
            })
        :
            data.filter(item => {
                return item.lineIndex !== null
                    ? item.ptdesKmMark[item.lineIndex].userLineName.toLowerCase().includes(this.state.lineFilterValue.toLowerCase())
                    && (this.getCommercialNameForFR(item.ptCarCommercialShortName)
                        .toLowerCase()
                        .includes(this.state.ptCarFilterValue.toLowerCase()) ||
                    this.getCommercialNameForNL(item.ptCarCommercialShortName)
                        .toLowerCase()
                        .includes(this.state.ptCarFilterValue.toLowerCase())
                    )
                    : null
            })
        this.setState({ filteredData: filteredData })
        this.filterCheckedTasks(filteredData)
    }


    getCommercialName(activeLanguage, nameObj) {
        if (nameObj === undefined || nameObj[0] === undefined || nameObj[1] === undefined) return ''
        if (activeLanguage.code === 'fr') return nameObj[0].translation
        else return nameObj[1].translation
    }
    getCommercialNameForFR(nameObj) {
        if (nameObj === undefined || nameObj[0] === undefined || nameObj[1] === undefined) return ''
        return nameObj[0].translation
    }
    getCommercialNameForNL(nameObj) {
        if (nameObj === undefined || nameObj[0] === undefined || nameObj[1] === undefined) return ''
        return nameObj[1].translation
    }

    persist = type => props => {
        const toSetState = { [type]: props }
        this.setState(toSetState)
    }

    getData() {
        if (this.state.lineFilterValue || this.state.ptCarFilterValue || this.state.ptDesFilterValue) return this.state.filteredData
        else return this.state.data
    }

    render() {
        const { classes, translate, activeLanguage} = this.props

        return (
            <div>
                <Header isMenu title={translate('ptdes_overview')} />
                <div className={classes.parentDiv} style={{ display: 'flex' }}>
                    <div className={classes.left}>
                        <div >
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <Typography style={{ marginRight: 10 }}>{translate('datatable_header_ptcar_line')}</Typography>
                                <input
                                    type="text"
                                    className={this.state.ptDesFilterValue != '' ? classes.customInputDisabled : classes.customInput}
                                    placeholder={this.state.ptDesFilterValue != '' ? '' : translate('search')}
                                    value={this.state.lineFilterValue}
                                    onChange={e => this.handleSearchFilters(e.target.value, 'lineFilterValue')}
                                    disabled={this.state.ptDesFilterValue != ''}
                                    />
                            </div>
                        </div>
                        <div style={{marginRight: '0px', display: 'flex', flexDirection: 'column', alignItems: 'end', rowGap: '16px'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <Typography style={{ marginLeft: 10, marginRight: 10 }}>
                                    {translate('datatable_header_ptcarplatform_ptcar')}</Typography>
                                <input
                                    className={this.state.ptDesFilterValue != '' ? classes.customInputDisabled : classes.customInput}
                                    placeholder={this.state.ptDesFilterValue != '' ? '' : translate('search')}
                                    value={this.state.ptCarFilterValue}
                                    onChange={e => this.handleSearchFilters(e.target.value, 'ptCarFilterValue')}
                                    disabled={this.state.ptDesFilterValue != ''}
                                    />
                            </div>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <Typography style={{ marginLeft: 10, marginRight: 10 }}>
                                    {translate('datatable_header_ptdesplatform_ptdes')}</Typography>
                                <input
                                    className={this.state.lineFilterValue != '' || this.state.ptCarFilterValue != '' ? classes.customInputDisabled : classes.customInput}
                                    placeholder={this.state.lineFilterValue != '' || this.state.ptCarFilterValue != '' ? '' : translate('search')}
                                    value={this.state.ptDesFilterValue}
                                    onChange={e => this.handleSearchFilters(e.target.value, 'ptDesFilterValue')}
                                    disabled={this.state.lineFilterValue != '' || this.state.ptCarFilterValue != ''}
                                    
                                    />
                            </div>
                        </div>
                    </div>
                    <div className={classes.right}>
                        <div>
                            <Button
                                style={{ marginRight: '5px' }}
                                variant="contained"
                                color="primary"
                                onClick={this.lindaSettingClick}
                                disabled={this.state.checkedTasks.length < 1}
                                >
                                {translate('linda_settings')}
                            </Button>
                        </div>
                    </div>
                </div>
                {this.state.loadingData && <Loading />}
                {!this.state.loadingData && (
                    <PtdesTable
                        columns={columns}
                        data={this.getData()}
                        classes={classes}
                        headerRenderer={this.headerRenderer}
                        cellRenderer={this.cellRenderer}
                        getRowClassName={this.getRowClassName}
                    />
                )}

                {this.state.isOpen && (
                    <PtdesDrawerView
                        rowData={this.state.rowData}
                        isOpen={this.state.isOpen}
                        onClose={this.onClose}
                        persist={this.persist}
                        loadPtdes={this.loadPtdes}
                    />
                )}

                {this.state.openLindaDrawer && (
                    <LindaDrawerView
                        rowData={this.state.rowData}
                        isOpen={this.state.openLindaDrawer}
                        onClose={this.onCloseLindaDrawer}
                        onChangeDate={this.dateChangeHandler}
                        activeLanguage={activeLanguage}
                        copyToAllHandler={this.handleCopyToAll}
                        lindaSettingDates={this.state.lindaSettingDates}
                        onSaveClick={this.onLindaSettingSave}
                        lindaSettingUpdateError={this.state.lindaSettingUpdateError}
                    />
                )}
            </div>
        )
    }
}

export default compose(withLocalize, withStore, withRouter, withStyles(styles))(PtdesListView)
