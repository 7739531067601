export default theme => ({
    logo: {
        position: 'absolute',
        height: 67,
        left: 16,
        top: 0,
        [theme.breakpoints.up('sm')]: {
            height: 77,
            left: 24,
        },
    },
    flex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    date: {
        flexGrow: 1,
        textAlign: 'center',
    },
    backButton: {
        marginRight: theme.spacing(2),
    },
    loginBody: {
        marginTop: 56,
        height: `calc(100vh - ${56}px)`,
        [theme.breakpoints.up('sm')]: {
            marginTop: 64,
            height: `calc(100vh - ${64}px)`,
        },
        background: "url('/img/portal_login_bg.png')",
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        // backgroundSize: "100%",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    title: { transform: 'translateX(-8px)' },
    loginDialog: {
        width: '350px',
        background: theme.palette.grey['900'],
        padding: theme.spacing(4),
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2),
    },
    loginTitle: {
        color: theme.customColors.whiteColor,
        marginBottom: theme.spacing(2),
    },
    errorText: { marginTop: theme.spacing(2), color: theme.customColors.errorText },
    loginText: { color: theme.customColors.whiteColor, marginBottom: theme.spacing(4) },
    loginButton: {
        padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
    },
    languagePicker: {
        width: '350px',
        marginBottom: theme.spacing(4),
    },
    urlMessage: {
        width: '80%',
        maxWidth: '1000px',
        background: theme.palette.grey['900'],
        opacity: 0.8,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'space-between',
        margintTop: theme.spacing(2),
    },
    urlMessageText: {
        color: theme.customColors.whiteColor,
        fontSize: '16px'
    },
    urlMessageLink: {
        '&:link': {
            color: '#2196f3',
        },
        '&:visited': {
            color: '#2196f3',
        },
    },
})
